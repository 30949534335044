import React, { useState, useEffect } from 'react';
import "./contact.css";
import address from "./img/Contact_img/address.svg";
import mail from "./img/Contact_img/mail.svg";
import my_app from "./img/Contact_img/address.svg";
import newsletter from "./img/Contact_img/newsletter.svg";
import people_search from "./img/Contact_img/people_search.svg";
import phone_call from "./img/Contact_img/phone_call.svg";
import searching from "./img/Contact_img/searching.svg";
import Coverphoto from './Coverphoto';
import contact_img from "./img/Cover_img/contact-us.jpg";
import Validators from "validator";

const Contact = () => {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        number: ""
    })
    const [errorMsg, setErrorMsg] = useState({
        eName: "",
        eEmail: "",
        eMessage: "",
        eNumber: "",

    })





    const { isEmail, isEmpty, isInt, isMobilePhone, isIn } = Validators;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((preVal) => { return { ...preVal, [name]: value } })
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        const { name, email, message, number } = formData
        if (!isEmpty(message) && message.length > 15) {
            console.log("hi");
            if (!isEmpty(name)) {

                if (isEmail(email)) {

                    if (isInt(number) && number.length === 10) {
                        alert("successfuly")
                    } else {
                        setErrorMsg({ ...errorMsg, eNumber: "Please enter currect Number" })
                    }
                    setErrorMsg((val) => { return { ...val, eEmail: " " } });
                } else {
                    setErrorMsg({ ...errorMsg, eEmail: "Please enter currect Email" })
                }
                setErrorMsg((val) => { return { ...val, eName: " " } });

            } else {
                setErrorMsg({ ...errorMsg, eName: "Please enter currect Name" })
            }
            setErrorMsg((val) => { return { ...val, eMessage: " " } });

        } else {
            setErrorMsg({ ...errorMsg, eMessage: "Message length should be grater then 15" })
        }
    }

    return (
        <>
            <Coverphoto All_cover={contact_img} Cover_heading={"Contact Us"} />

            <div className="touch">
                <h1>Get in Touch</h1>
            </div>
            <div className="info_address">
                <div className="information">
                    <form onSubmit={handleSubmit} >

                        <div className="detail">
                            <input id="username" name="name" type="text" placeholder="Enter Name" onChange={handleChange} />
                            <p id="para2" style={{ color: 'red' }}>{errorMsg.eName}</p>
                            <input id="email" name='email' type="email" placeholder="Email" onChange={handleChange} />
                            <p id="para3" style={{ color: 'red' }}>{errorMsg.eEmail}</p>
                            <input id="phone" name='number' type="text" placeholder="Enter your phone number" onChange={handleChange} />
                            <p id="para4" style={{ color: 'red' }} >{errorMsg.eNumber}</p>
                            <div className="message">
                                <textarea className="form_control_textarea" rows="3" name="message" id="message" placeholder="Enter Message" onChange={handleChange} />
                            </div>
                            <p id="para1" style={{ color: 'red' }}>{errorMsg.eMessage}</p>
                            <input type='submit' value='Submit' />
                        </div>

                    </form>
                </div>

                <div className="address1">
                    <div className='adressBar'>
                        <div className="place">

                            <img src={address} alt="" />
                            <div className="media">
                                <p>Address:- MVV8+VGV,206,2nd floor , orange business park, Bhawarkua Main Rd, near Apple
                                    Hospital, Transport Nagar, Indore, MadhyaPradesh 452001</p>
                            </div>
                        </div><br />
                        <div className="place">
                            <img src={phone_call} alt="" />
                            <div className="media">
                                {/* <p>+91 9479574926 (HR)</p> */}
                                <p>+91 8871800779 (HR)</p>
                                <p style={{ color: 'silver' }}>Mon to Sat 10am to 7pm</p>

                            </div>

                        </div><br />
                        <div className="place">
                            <img src={mail} alt="" />
                            <div className="media">
                                <p>info@sonnentechs.com (HR)</p>
                                <p style={{ color: 'silver' }}>Send us your query anytime!</p>
                            </div>
                        </div>
                    </div>

                    <div className="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.9016558330545!2d75.86407911399996!3d22.694704634199184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd21a990e8bf%3A0xa5845ba1d3981efb!2sSonnen%20Techs!5e0!3m2!1sen!2sin!4v1678009261211!5m2!1sen!2sin"
                            width="640" height="900" style={{ border: 0 }} allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
