import React from 'react';

const EventPopover = ({ event, onClose, position }) => {
  const handleClose = () => {
    onClose(); // Call onClose function passed from parent component to close the popover
  };

  const popoverStyle = {
    color:"black",
    position: 'absolute',
    top: position.y,
    left: position.x,
    backgroundColor: 'white',
    boxShadow: `rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset`,
    padding: '20px',
    cursor:"pointer",
    borderRadius:"10px",
    zIndex: 100
  };

  const formatTime = (date) => {
    return date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
  };

  return (
    <div style={popoverStyle}>
      <div className="popover-content">
        <button className="close-button" onClick={handleClose}>Close</button>
        <h3>{event.title}</h3>
        <p><strong>Start:</strong> {formatTime(event.start)}</p>
        <p><strong>End:</strong> {event.end ? event.end.toLocaleString() : 'Unknown'}</p>
        <p><strong>URL:</strong> {event.url || 'No URL provided'}</p>
      </div>
    </div>
  );
};

export default EventPopover;
