const sliderData = [
    {
      id: 1,
      tech: "Experience Personal Consulting",
      image: require("../img/Slider_img/slider4.jpg"),
      descrption: "We believe in making your dreams and goals a reality and our experts know just how to do that. We're prepared to help you at any level, from impartial advice or practical assistance to help you make the most of every opportunity. Whether you're just starting out or looking to restructure we've got you covered.",
      btn:"Learn More"
    },
    {
      id: 2,
      tech: "We Help You to Design Success",
      image: require("../img/Slider_img/slider3.webp"),
      descrption: "Business mentors are key—that’s why when it comes to client selection, we’re choosy. We want to give each of you the time and guidance you deserve. Whether you’re seeking a strategic alliance with the right partner or a special skillset or tool, call us today. Together we’ll create and refine your plan for success. We didn’t get there alone.",
      btn:"Make Appoinment",
    },
    {
      id: 3,
      tech: "Management - Software - Accounting",
      image: require("../img/Slider_img/slider6.jpg"),
      descrption: "Our service includes a comprehensive consult to help identify gaps and opportunities, a comprehensive report that includes a project plan with timelines and milestones, a cost analysis, and a schedule. We also offer a suite of quality products that will help you get there quickly and smoothly.",
      btn:"Our Services"
    },
  ];
  export default sliderData;
