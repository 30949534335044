import React from "react";
import "./about.css";
import image from "../components/img/pexels-fauxels-3184339.jpg";

const About = () => {
  return (
    <div className="container">
      <div className="contentBar">
        <div className="contentTextBox">
          <div className="contentText">
            <div className="headings">
              <h1>About Us</h1>
              <div class="heading-line">
                <span class="short-line"></span>
                <span class="long-line"></span>
              </div>
            </div>
            <h2>Why are we</h2>
            <p>
              Our Journey started as freelancers as time passed,the number of project were induced as a result Our team stared to Expand.
            </p>
            {/* <p className="extraText">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure
              dignissimos unde architecto quod eaque quibusdam accusantium
              cumque corporis laboriosam odit?
            </p> */}
          </div>
        </div>
        <div className="imgBar">
          <img src={image} alt="" />
        </div>
      </div> 

      <div className="bootomContainer">
        <div className="bottemContent">
          <div className="bottemText">
            <div className="bootemHead">
              <h2>Our Vision</h2>
            </div>
            <ul>
              <li>We Believe in Offering high aim Project</li>
              <li>
                Perfection is Our Goal but excellent quality is our guarantee
              </li>
              <li>
                Our Work is our Passion, and Our Passion create something new
                and creative everytime.
              </li>
            </ul>
          </div>
          <div className="bottemText">
            <div className="bootemHead">
              <h2>Our Mission</h2>
            </div>
            <ul>
              <li>
                Our Mission is to serve ourclient with the best and for that We
                use latest technologies and methods available in the market
                today
              </li>
              <li>
                Pay attention to small staff Scheduking of timelines and keen project management
              </li>

            </ul>
          </div>
          <div className="bottemText">
            <div className="bootemHead">
              <h2>Our Services</h2>
            </div>
            <ul>
              <li>Mobile app Development</li>
              <li>Web Application Development</li>
              <li>
                Our Work is our Passion, and Our Passion create something new
                and creative everytime.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
