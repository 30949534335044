import React,{useState,useEffect} from "react";
import career from "./img/Contact_img/newsletter.svg";
import Coverphoto from "./Coverphoto";
import "./career.css";
import svgdot from "./img/Career_img/svgdot.png";
import react from "./img/Career_img/react.svg";
import js from "./img/Career_img/js.jpg";
import test from "./img/Career_img/test.jpg";
import ror from "./img/Career_img/ror.jpg";
import Card from "./Card";
import homeData from "./Data/homeData";

const Career = () => {
  // const [showHeading, setShowHeading] = useState(false);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const heading = document.getElementById('heading');

  //     if (heading) {
  //       const rect = heading.getBoundingClientRect();
  //       console.log(rect);
  //       console.log(rect.top,rect.left, window.innerHeight, window.innerWidth);
  //       const isInViewport = rect.top <= -928 && rect.left >= 0 && rect.bottom <= window.innerHeight && rect.right <= window.innerWidth;

  //       if (isInViewport) {
  //         setShowHeading(true);
  //       }
  //     }
  //   };
  //   // Attach scroll event listener
  //   window.addEventListener('scroll', handleScroll);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  return ( 
    <>
      {/* <Coverphoto All_cover={career} Cover_heading={"Career"} /> */}
      <div className="bgimage">
        <div className="join">
          <div className="heading" id="heading">
            <h1>
              Join Our Forward-Thinking <br />
              Innovators 
            </h1>
          </div>
          <div className="services-box">
            {/* {homeData.map((item) => {
              return (
                <Card
                  image={item.image}
                  tech={item.tech}
                  id={item.id}
                  descrption={item.descrption}
                />
              );
            })} */}

            <h1>Join Our Team At</h1>
            <h2>Sonnen Tech</h2>
            <div className="carrerPara">
            <p>We are committed to nurturing Creativity & Innovation. If you are passionate and determined to change the world, we have a seat for you...</p>
            </div>
            <button>Apply Now</button>
            {/* <div className="box1 box_career">
              <div className="service-icon">
                <img src={svgdot} alt="Image" className="icon-bar icon-bar12" />
                <img src={react} alt="Image" className="icon" />
              </div>
              <h4><a href="#">REACT NATIVE</a></h4>
              <p>Qualification :- Btech (CS/IT) , MCA completed Good communication skills</p>
              <a href="#"> <button className="btn">APPLY NOW</button></a>
            </div>
            <div className="box2 box_career">
              <div className="service-icon">
                <img src={svgdot} alt="Image" className="icon-bar  icon-bar12" />
                <img src={js} alt="Image" className="icon" />
              </div>
              <h4><a href="#">REACT JS</a></h4>
              <p>Qualification :- Btech (CS/IT) , MCA completed Good communication skills</p>
              <a href="#"> <button className="btn">APPLY NOW</button></a>
            </div>

            <div className="box3 box_career">
              <div className="service-icon">
                <img src={svgdot} alt="Image" className="icon-bar icon-bar12" />
                <img src={ror} alt="Image" className="icon" />
              </div>
              <h4><a href="#">ROR(RUBY ON RAILS)</a></h4>
              <p>Qualification :- Btech (CS/IT) , MCA completed Good communication skills</p>
              <a href="#"> <button className="btn">APPLY NOW</button></a>
            </div>
            <div className="box4 box_career">
              <div className="service-icon">
                <img src={svgdot} alt="Image" className="icon-bar icon-bar12" />
                <img src={test} alt="Image" className="icon" />
              </div>
              <h4><a href="#">QA & TESTING</a></h4>
              <p>Qualification :- Btech (CS/IT) , MCA completed Good communication skills</p>
              <a href="#"> <button className="btn">APPLY NOW</button></a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
