import React, { useState, useEffect } from "react";
import "./login.css"
import Logo from "./img/SONNEN-LOGO.png";
// import RightImg from "../Assets/PartnerImg/RightImgLogin.jpg";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import SignUpScreen from "./SignUpScreen";

export const IsLogedIn = () => {
    const data = localStorage.getItem("token");
    if (data == null) {
        return false;
    } else if (data == undefined) {
        return false;
    } else {
        return true;
    }
};

export default function () {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    // console.log(username, password);
    // const [passwordInput, setPasswordInput] = useState("");
    // const handlePasswordChange = (evnt) => {
    //   setPasswordInput(evnt.target.value);
    // };
    // const togglePassword = () => {
    //   if (password === "text") {
    //     setPassword("password");
    //     return;
    //   }
    //   setPassword("text"); 
    // };

    // const [token, setToken] = useState();
    // const [image,setImage] = useState();
    //   const Navigate = useNavigate();

    //   const formsubmitfunc = async (e) => {
    //     e.preventDefault();

    //     const formData = new FormData();
    //     formData.append("username", username);
    //     formData.append("password", password);

    //     axios
    //       .post("https://miraajewel.com/API/Web/user/login", formData, {
    //         headers: { "Content-Type": "multipart/from-data" },
    //       })
    //       .then((res) => {
    //         console.log(res.data); 
    //         if (res.data.success === true) {
    //           localStorage.setItem("token", res.data.data.AUTH_KEY);
    //           localStorage.setItem("name", res.data.data.username);
    //           localStorage.setItem("id", res.data.data.id);
    //           console.log(res.data.data.id);
    //           return Navigate("/version2");
    //         } else {
    //           alert("Wrong Username or password, please try again.");
    //         }
    //       })
    //       .catch((error) => {
    //         console.log("error" + error);
    //       });
    //   };

    return (
        <>
            <section className="login_main">
                <div className="login_mdiv">
                    <div className="login_slogan">
                        <img src={Logo} alt="logo" />
                        <h4>
                            We are The SonnenTech Team
                        </h4>
                    </div>
                    <form >
                        <p style={{ marginBottom: '1rem', fontFamily: 'Montserrat', fontSize: '1rem' }} className="">
                            Please login to your account
                        </p>
                        <div style={{ marginBottom: '1rem' }} className="login_formdiv">
                            <input type="text" id="exampleFormControlInput1" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className="login_passwordiv">
                            <input type={password} id="exampleFormControlInput1" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="loginbutton_div">
                            <NavLink to="/calendar">
                            <button type="submit" data-mdb-ripple="true" data-mdb-ripple-color="light">
                                Log in
                            </button>
                            </NavLink>
                            <NavLink to="/login">
                                <a style={{ color: '#718096', fontFamily: 'Montserrat' }} className="" href="">
                                    Forgot password?
                                </a>
                            </NavLink>
                        </div>
                    </form>
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '1.5rem' }} className="">
                        <p style={{ marginBottom: '0', marginRight: '0.5rem', fontFamily: 'Montserrat' }} className="">
                            Don't have an account?
                        </p>
                    </div>
                </div>
            </section>

        </>
    );
}
