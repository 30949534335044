import React from 'react';
import { NavLink } from 'react-router-dom';
import "./footer.css";
import logo from "./img/SONNEN-LOGO.png";
import newsLetter from "./img/Contact_img/newsletter.svg";

const Footer = () => {
    return (
        <>
        <footer>
            <div className="foot">
                <div className="sonnenteach">
                    <img src={logo} alt="sonnen" /><br /><br/>
                        <p>Sonnen Technologies is a trusted and highly accredited organisation that provides IT consultancy
                            services and technology solutions.</p>
                </div>
                <div className="quick">
                    <h3>Quick links</h3>
                    <li><NavLink to="/about">About</NavLink></li>
                    <li><NavLink to="/meet">Meet the Team</NavLink> </li>
                    <li><NavLink to="/services">Services</NavLink> </li>
                    <li><NavLink to="/career">Career</NavLink> </li>
                    <li><NavLink to="/gallery">Gallery</NavLink> </li>
                    <li><NavLink to="/contact">Contact Us</NavLink> </li>
                </div>
                <div className="platfrom">
                    <h3>platforms</h3>
                    <li><NavLink to="">Android/IOS</NavLink></li>
                    <li><NavLink to="">Hybrid/Native Applications</NavLink> </li>
                    <li><NavLink to="">Web Application</NavLink> </li>
                    <li><NavLink to="">ROR(Ruby On Rails)</NavLink> </li>
                    <li><NavLink to="">QA</NavLink> </li>
                </div>
                <div className="newstellers">
                    <h3>Newsletter</h3>
                    <p>Heaven fruitful doesn't over lesser in days Appear.</p><br />
                        <img src={newsLetter} alt="" />
                </div>
            </div>

            <div className="copyright">
                <p>Copyright ©2023 All rights reserved | Design & Developed by <NavLink to="">SonnenTechs</NavLink></p>
            </div>

        </footer>
      

        </>
    )
}

export default Footer