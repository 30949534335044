import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./home.css";
// import homeCover from "./img/homeCover.svg";
import app1 from "./img/home_img/app1.png";
import app2 from "./img/home_img/app2.png";
import app3 from "./img/home_img/app3.png";
import app4 from "./img/home_img/app4.png";
// import client from "./img/home_img/client.jpg";
// import client2 from "./img/home_img/client2.jfif";
// import client3 from "./img/home_img/client3.jfif";
import commerce from "./img/home_img/commerce.jpg";
// import development from "./img/home_img/development.svg";
import elearning from "./img/home_img/e-learning.jpg";
import food from "./img/home_img/food.svg";
import medical from "./img/home_img/medical.svg";
import mobilehome from "./img/home_img/mobile-home.jpg";
// import mobile from "./img/home_img/mobile.svg";
// import product from "./img/home_img/product.svg";
// import svgdot from "./img/home_img/svgdot.png";
// import team from "./img/home_img/team.svg";
// import testing from "./img/home_img/testing.svg";
// import uxui from "./img/home_img/uxui.svg";
import design1 from "./img/home_img/web-design1.svg";
import Card from "./Card";
// import Cardcontainer from './Cardcontainer';
import homeData from "./Data/homeData";
import sliderData from "./Data/sliderData.js"
import AutoSlider from "./slider.jsx"
import About from "./About.jsx";
import { Element } from 'react-scroll';
import Career from "./Career.jsx";
import Contact from "./Contact.jsx";
import { BiUpArrowAlt } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Home = () => {
  // useEffect(() => {
  //   window.history.scrollRestoration = "manual";
  // }, []);

  // scrollbutton
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    // Show the button when the user scrolls down 100 pixels or more
    setIsVisible(window.scrollY > 100);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // <---------------------service------------->

  const scrollContainerRef = useRef(null);

  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 200; // Adjust the scroll distance as needed
    }
  };

  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 200; // Adjust the scroll distance as needed
    }
  };

  // <---------------------------------------------->
  return (
    <>
      <main> 
        <div style={{ position: 'fixed', bottom: '45px', right: '20px', display: isVisible ? 'block' : 'none' }}>
          <button onClick={scrollToTop}><BiUpArrowAlt style={{ fontSize: "30px" }} /></button>
        </div>
        <div className="cover_Detail">
          {/* <div className="details">
            <h4>Sonnen Tech</h4>
            <h1> 
              Building <br /> Future <br /> With Tech
            </h1>
            <h3>
              Yes,You read it right,We are here to enhance your idea of business
              by providing the best appliaction you could have.
            </h3>
          </div>
          <img className="coverPhoto" src={homeCover} alt="" srcset="" /> */}

          <AutoSlider items={sliderData} interval={8000} />
        </div>
        <div className="container_Home">
          <div className="container-right">
            <div className="rightbox1">
              <div className="container-left">
                <img src={design1} alt="image" />
              </div>
              <h1>Best Domians of our Apps </h1>
            </div>
            <div className="rightbox2">
              <div className="commerce-box">
                <img src={commerce} alt="img" />
                <div className="commerce">
                  <h2>Creating better IT Solutions</h2>
                  <p>
                    We are here to transfigure your ideas of business to
                    appliactions
                  </p>
                </div>
              </div>
              <div className="commerce-box">
                <img src={elearning} alt="img" />
                <div className="commerce">
                  <h2>Transform Every Digital Process</h2>
                  <p>We recast your innovative ideas to Business Solutions</p>
                </div>
              </div>
              <div className="commerce-box">
                <img src={medical} alt="img" />
                <div className="commerce">
                  <h2>Full Stack Development</h2>
                  <p>
                    From ideation to deployment We are here to grow your
                    business Online
                  </p>
                </div>
              </div>
              <div className="commerce-box">
                <img src={food} alt="img" />
                <div className="commerce">
                  <h2>Food App</h2>
                  <p>
                    We are sonnenTech use Digital technologies to tarnsform
                    traditional non-digital business processes services to
                    digital
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ------------------------------------------------------------------------------ --> */}

        {/* About Section */}
        <Element name="about">
          <About />
        </Element>

        {/* <-------------------------------------------------------------------------------> */}
        <Element name="Services">
        </Element>
        <div className="container-services">
          <h1>
            How Can We Help You <br />
            with Sonnen Tech!
          </h1>
        </div>


        {/* Right Scroll Icon */}

        <div className="container-page">
        <div className="scroll-icons">
        {/* Left Scroll Icon */}
        <div className="scroll-icon left" onClick={handleScrollLeft}>
          <FaChevronLeft />
        </div>
          <div className="service-box" ref={scrollContainerRef}>    
            {homeData.map((item) => {
              return (
                <Card
                  image={item.image}
                  tech={item.tech}
                  id={item.id}
                  descrption={item.descrption}
                />
              )
            })}
          </div>
        <div className="scroll-icon right" onClick={handleScrollRight}>
          <FaChevronRight />
        </div>
      </div>
        </div>

        <div className="mobile-slide">
          <div className="mobile-left">
            <h1>Why Choose Us</h1>
            <h6>Attention to Details</h6>
            <p>
              At Sonnen Tech, we've always tried to do things a little
              differently. We believe that a company can grow with a clear conscience and succeed with a soul.
            </p>
            <h6>Pricing</h6>
            <p>
              Our Prices are Competitive and fair There are no Surprise bills
            </p>
          </div>
          <div className="mobile-right">
            <img src={mobilehome} alt="image" />
          </div>
        </div>

        {/* <------------------------------------------------------------> */}
        {/* Career */}

        <Element name="Career" style={{ marginTop: "20px" }}>
          <Career />
        </Element>
        {/* <-----------------------------------------------------------> */}
        {/* <div className="customer-container">
          <h1>See What Our Customers Have to Say</h1>
        </div> */}

        {/* <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="face front-face">
                <img
                  src="https://images.unsplash.com/photo-1557862921-37829c790f19?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80"
                  alt=""
                  className="profile"
                />
                <div className="pt-3 text-uppercase name">Robert Garrison</div>
                <div className="designation">Android Developer</div>
              </div>
              <div className="face back-face">
                <span className="fas fa-quote-left"></span>
                <div className="testimonial">
                  I made bacck the purchase price in just 48 hours! Thank you
                  for making it pain less, pleasant. The service was execellent.
                  I will refer everyone I know.
                </div>
                <span className="fas fa-quote-right"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="face front-face">
                <img
                  src="https://images.unsplash.com/photo-1600486913747-55e5470d6f40?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                  alt=""
                  className="profile"
                />
                <div className="pt-3 text-uppercase name">Jeffery Kennan</div>
                <div className="designation">Full Stack Developer</div>
              </div>
              <div className="face back-face">
                <span className="fas fa-quote-left"></span>
                <div className="testimonial">
                  Really good, you have saved our business! I made bacck the
                  purchase price in just 48 hours! man, this thing is getting
                  better and better as I learn more about it.
                </div>
                <span className="fas fa-quote-right"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="face front-face">
                <img
                  src="https://images.unsplash.com/photo-1614574762522-6ac2fbba2208?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjY2fHxtYW58ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  className="profile"
                />
                <div className="pt-3 text-uppercase name">Issac Maxwell</div>
                <div className="designation">Finance Director</div>
              </div>
              <div className="face back-face">
                <span className="fas fa-quote-left"></span>
                <div className="testimonial">
                  Account keeper is the most valuable business research we have
                  EVER purchased. Without electrician, we would ahave gone
                  bankrupt by now.
                </div>
                <span className="fas fa-quote-right"></span>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- --------------------------------------------- --> */}
        <div className="application">
          <div className="application-left">
            <h1>Sonnen Tech Developed Applications</h1>
            <div className="application-img">
              <img src={app1} alt="image" />
              <img src={app2} alt="image" />
              <img src={app3} alt="image" />
              <img src={app4} alt="image" />
            </div>
          </div>
          <div className="application-right">
            <img src={design1} alt="image" />
          </div>
        </div>
        {/* <div className="contactUsHead">
          <h1> WE LOVE WHAT WE DO</h1>
        </div>
        <div className="contact-us">
          <div>
            <h1>Want to be a part of our team? Email your resume at -</h1>
          </div>
          <NavLink to="/contact">
            <p>hr@sonnentechs.com</p>
          </NavLink>
        </div> */}

        {/* <-------------------------------------------------------------> */}
        {/* Contact */}
        {/* <-------------------------------------------------------------> */}
        <Element name="Contact" style={{ marginTop: "20px" }}>
          <Contact />
        </Element>
      </main>
    </>
  );
};

export default Home;
