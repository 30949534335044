const homeData = [
  {
    id: 1,
    tech: "Android Development",
    image: require("../img/TechImage/pexels-pixabay-267350.jpg"),
    descrption: "We are your tech",
  },
  {
    id: 2,
    tech: "Web Development",
    image: require("../img/TechImage/Web Site.avif"),
    descrption: "We are your tech",
  },
  {
    id: 3,
    tech: "JAVA Development",
    image: require("../img/TechImage/JAVA.png"),
    descrption: "We are your tech",
  },
  {
    id: 4,
    tech: "React Development",
    image: require("../img/TechImage/React icon.avif"),
    descrption: "We are your tech",
  },
  {
    id: 5,
    tech: "Python Development",
    image: require("../img/TechImage/Python icon.avif"),
    descrption: "We are your tech",
  },
  {
    id: 6,
    tech: "Ruby Development",
    image: require("../img/TechImage/Ruby.avif"),
    descrption: "We are your tech",
  },
];
export default homeData;
