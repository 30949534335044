import React, { useState } from 'react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import "./calendar.css"
import EventPopover from "./EventPopover"


const Calendar = () => {
    const events = [
        { title: 'ReactJs Interview', start: '2024-02-07T12:00:00' },
        { title: 'Java Interview', start: '2024-02-08T13:10:00' }

    ]
    function renderEventContent(eventInfo) {
        console.log(eventInfo);
        return (
            <>
                <i>{eventInfo.timeText}</i>
                <strong style={{ margin: "0 5px" }}>{eventInfo.event.title}</strong>
            </>
        )
    }

    const [selectedEvent, setSelectedEvent] = useState(null); // State to store the selected event
    const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });

    const handleEventClick = (info) => {
        // Access the event information using info.event
        console.log('Event clicked:', info.event);
        setSelectedEvent(info.event); // Set the selected event in state
        setPopoverPosition({ x: info.jsEvent.clientX, y: info.jsEvent.clientY });
    };

    const handleClosePopover = () => {
        setSelectedEvent(null); // Clear selectedEvent state to close the popover
    };


    return (
        <div className='calendars'>
            <h1>Interview Scheduling</h1>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                weekends={true}
                events={events}
                eventContent={renderEventContent}
                height={"80vh"}
                eventClick={handleEventClick}
            />

            {selectedEvent && <EventPopover event={selectedEvent} onClose={handleClosePopover} position={popoverPosition} />}
        </div>
    )
}

export default Calendar