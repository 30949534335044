import React, { useState, useEffect } from 'react';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import "./slider.css"

const AutoScrollSlider = ({ items, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handleCircleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Calculate the next index and update the state
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      // setCurrentIndex((prevIndex) => (prevIndex + 1 === items.length ? 0 : prevIndex + 1));
    }, interval);

    // Cleanup function to clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [items, interval]);

  return (
    <div className='mainSlider'>

    
      <MdOutlineArrowBackIos onClick={handlePrevClick} className='arrowL'   /> {/* Left arrow */}
      
      <MdOutlineArrowForwardIos onClick={handleNextClick} className='arrowR'  /> {/* Right arrow */}
      {/* {console.log(items.descrption)} */}


      <div
        style={{
          position: '   ',
          bottom: '30px',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          zIndex:'1',
        }}
      >
        {items?.map((item, index) => (
          <div
            key={index}
            onClick={() => handleCircleClick(index)}
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              background: index === currentIndex ? 'white' : '#ccc',
              margin: '0 5px',
              cursor: 'pointer',
            }}
          ></div>
        ))}
      </div>



      {items?.map((item, index) => (
        <div
        className='sliderImage'
          key={item.id}
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),radial-gradient(circle at 10% 50%, #00b5f7 5%, transparent 50%), url(${item.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            transition: 'transform 0.5s ease',
            transform: `translateX(${-currentIndex * 100}%)`,
      
          }}
        >
          <div className='itemTech'>
            <h1 >{item.tech}</h1>
            <p style={{ whiteSpace: 'wrap', marginTop: "10px", fontSize: '16px', }}>{item.descrption}</p>
          </div>

        </div>
      ))}
    </div>
  );
};

export default AutoScrollSlider;


