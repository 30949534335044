import React from "react";
import svgdot from "./img/Services_img/svgdot.png";
import "./card.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import CellPhone from "./img/Services_img/cell-phone.png";

const Card = ({ image, tech, id, descrption }) => {

  // console.log(id);
  return (
    <>
      <div className="box1 box">
        <div className="service-icon">
          <img src={svgdot} alt="Image" className="icon-bar" />
          <div className="cellContainer">
            <img
              src={image}
              alt="Image"
              className="icon"
              // className="cellIcon"
            />
          </div>
        </div>
        <h4 id="heading">
          {tech}
        </h4>
        {/* <p>
          {descrption}
        </p> */}
        {/* <button>Apply Now</button> */}
      </div>
    </>
  );
};

export default Card;
